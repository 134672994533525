import './utils/sentry/instrument';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import store from './store';
import { App } from 'components';

import './assets/styles/app.scss';

/**
 * Required for accessibility reasons.
 * @see http://reactcommunity.org/react-modal/accessibility/
 */
Modal.setAppElement('#root');

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

